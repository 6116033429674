import React from "react"
import styled from '@emotion/styled';

import {H2White} from './index';

import {SEO} from '../components/Shared'
import {Layout, Routes,SocialIcons} from '../components/Layout';


const H2 = styled(H2White)`
  font-size: 20vw;
  letter-spacing: 2vw;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: black;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`;




const NotFoundPage = () => (
  <>
    <SEO title="Page Not Found" desc="Page Not Found - 7IDE is a team of designers, markerters, developers, and engineers, working together on various domains from designing, branding, software development and more to help businesses grow." />
   <Routes/>
    <SocialIcons/>
    <Layout>
      <Container>
        <H2>404</H2>
        <div>Oops... Are you lost?</div>
      </Container>
    </Layout>
  </>
)

export default NotFoundPage
